.name {
  display: flex;
  padding-left: 18px;
  font-size: 1.2em;
}

.job-desc {
  display: flex;
  justify-content: flex-start;
}

.ml3 {
  background: -webkit-linear-gradient(180deg, hsla(333, 100%, 53%, 1) 0%, hsla(33, 94%, 57%, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;   
}

@media only screen and (max-width: 900px) {
  .name {
    display: flex;
    flex-direction: column;
    justify-content:center;
  }
  
  .job-desc {
    display: flex;
    flex-direction: column;
    justify-content:center;
  }
}
