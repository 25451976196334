.content-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
    margin-left: auto;
    margin-right: auto;
}

.profile-img {
    box-shadow: rgba(255, 255, 255, 0.15) 0px 2px 8px;}

section {
   min-height: 80vh;
   width: 80%;
   margin-left: auto;
   margin-right: auto;
   /* overflow: auto; */
}

#experiences {
    min-height: 80vh;
    height: 30vh;
}


.top-corner-underline {
    width: 20%;
    font-weight: 300;
}

@media screen and (min-width: 763px) and (max-width: 990) {
    .content-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and  (max-width: 420px) {
    section {
        min-height: 60vh;
        width: 90%;
     }

     #experiences{
        min-height: 200vh;
        margin-top: 3vh;
    }

    #project {
        min-height: 100vh;
        margin-top: 100vh; 
    }

    .top-corner {
        margin-left: 3rem;
    }
    
    .bottom-corner {
        margin-right: 3rem;
    }

    .footer-contract {
        margin-top: 30%;
    }
}

@media only screen and  (max-width: 763px) and (min-width: 401px) {
    section {
        min-height: 60vh;
        width: 90%;
     }

     #experiences{
        min-height: 100vh;
        margin-top: 3vh;
    }

    #project {
        min-height: 100vh;
        margin-top: 150vh; 
    }

    .footer-contract {
        margin-top: 30%;
    }
}
