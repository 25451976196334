body {
  /* display: flex;
  justify-content: center; */
  /* background-color: #e6e6e6; */
  background-color: #fff;
  color: #000;
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
}


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
