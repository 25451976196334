.project-wrapper {
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;

    .project-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        // flex-direction: row;
        // justify-content: center;
    
        
    }

    @media only screen and  (max-width: 400px) {
        .project-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    @media only screen and  (max-width: 763px) and (min-width: 401px) {
        .project-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    @media only screen and  (max-width: 1200px) and (min-width: 763px) {
        .project-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
}


