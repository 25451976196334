.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 33vh;

}

.bi-arrow-bar-down {
    margin-top: 4vh;
    height: 6vh;
}
