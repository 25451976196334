.aboutme-body{ 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;

  .aboutme-content-header { 
    font-weight: 700;
  }

  .aboutme-img { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    filter: grayscale(20%);

    img { 
      width: 220px;
      box-shadow: 10px 10px 15px rgba(0,0,0,0.5);
      border-radius: 10px;
      background: rgba(218, 221, 201, 0.205);
      backdrop-filter: blur(30px);
      z-index: 1;
    }
  }

  .profile-body { 
    width: 800px;
  }
}

.work-exp-body { 
  margin-top: 10%;

  .work-content-header { 
    font-weight: 700;
  }

  .work-exp-wrapper { 

    .title-wrapper { 
      display: flex;
      flex-direction: row;
    }
  
    .work-exp-header { 
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 700;
    }
  
    .work-exp-title { 
      text-decoration: underline;
    }
  
    .work-exp-company { 
      font-weight: 300;
      font-size: 15px;
      font-style: italic;
    }
  
    .work-exp-task { 
      font-size: 15px;
    }
  }
}

@media (min-device-width: 360px) and (max-device-width: 812px)  {
  .aboutme-body { 
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .aboutme-content { 
      margin-left: auto;
      margin-right: auto;
      width: 100%; 
        
      .profile-body{ 
        width: 100%; 
      }
    }

    .aboutme-img { 
      margin-top: 5%;
      margin-left: auto;
      margin-right: auto;
      width: 150px;
      height: 200px;

      img { 
        border-radius: 10px;
      }
    }
  }

  .work-exp-body { 
    font-size:  12px;
  }

}