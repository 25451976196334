.card-project {
    display: flex;
    border-top: 1px solid rgba(189, 182, 153, 0.589) ;
    border-left: 1px solid rgba(255, 255, 255, 0.5) ;
    backdrop-filter: blur(5px);
    box-shadow: 15px 15px 60px rgba(0,0,0,0.5);
    margin: 45px;
    border-radius: 20px;
    overflow: hidden;

    :hover {

        .project-image { 
            filter: grayscale(0%);
            transition: .4s;
        }

        .project-title { 
            background: -webkit-linear-gradient(90deg, hsla(333, 100%, 53%, 1) 0%, hsla(33, 94%, 57%, 1) 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: .6s;
        }

    }
    
    a {
        color: #000;
        text-decoration: none;
    }

    .skill {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        font-weight: 300;
        font-size: small;
        flex-wrap: wrap;
    }
    
    span {
        color: black;
        margin: 8px;
        border-radius: 10px;
        padding: 10px;
        font-weight: 300;
        border: 1px solid #000;
        font-size: 12px;
        // background-color: #f3a51f;
    }

    span:hover {
        border: 1px solid #f3a51f;
        transition: .6s;
    }


    .project-link {
        width: 100%;
        height: 100%;
    }
    
    .project-image {
        width: 100%;
        height: 200px;
        filter: grayscale(100%);
        background-size: 100%;
    }

    .project-desc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .project-title {
        font-weight: 700;
        line-height: 1.1;
        margin-top: 20px;
        font-size: 14px;
    }

    .desc-text { 
        font-weight: 300;
        font-size: 12px;
        margin-left: 10px;
        margin-right: 10px;
    }

    @media (min-device-width: 340px) and (max-device-width: 1024px)  {
        .card-project {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-top: 1px solid rgba(255, 255, 255, 0.5) ;
            border-left: 1px solid rgba(255, 255, 255, 0.5) ;
            backdrop-filter: blur(5px);
            box-shadow: 20px 20px 50px rgba(0,0,0,0.5);
            border-radius: 20px;
            overflow: hidden;
        }

        .project-image {
            width: 100%;
            height: 120px;
            background-size: 100%;
            filter: grayscale(100%);
        }
    
        .project-desc {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5%;
            width: 100%;
            height: 100%;
        }
    
        .project-title {
            font-weight: 700;
            line-height: 1.1;
            margin-top: 20px;
            font-size: 12px;
        }
    
        .desc-text { 
            font-weight: 300;
            font-size: 11px;
            margin-left: 10px;
            margin-right: 10px;
        }

        .skill {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            font-weight: 300;
        }

        span { 
            font-size: 9px;
            margin: 2px;
        }

    }
}

