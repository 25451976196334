.card-item {
    margin: .6rem;
    min-height: 7vh;
    min-width: 4%;
    height: 45px;
    width: 90px;
    box-shadow: 12px 12px 20px rgba(0,0,0,0.5);
    border-radius: 15px;
    background: rgba(218, 221, 201, 0.205);
    overflow: hidden;
    border-top: 1px solid rgba(255, 255, 255, 0.5) ;
    border-left: 1px solid rgba(255, 255, 255, 0.5) ;
    backdrop-filter: blur(20px);
    z-index: 1;
            
    .icon {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        padding: .8rem;
        font-weight: 300;
        font-size: medium;
        transition: 0.5s;
        background: -webkit-linear-gradient(90deg, hsla(333, 100%, 53%, 1) 0%, hsla(33, 94%, 57%, 1) 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &:hover {
        .icon {

            color: #000;
            // background: linear-gradient(to right top, rgba(93, 99, 116, 0.1), rgba(51, 57, 73, 0.9));
            justify-content: flex-start;
            transition: all .3s ease-out;
        }
    }
}

@media (min-device-width: 340px) and (max-device-width: 812px)  {

    .card-item {

        margin: .5rem;
        height: 70px;
        width: 100px;


        .icon {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-size: 14px;
        }
    }
}
